<template>
  <div class="password_dialog">
    <vDialog :visible.sync="visible" :visibleFooter="true">
      <div class="top">
        <p class="name">{{ $t('common.field.accNum') }}</p>
        <span class="number">{{ number }}</span>
      </div>
      <div class="form_button">
        <el-button class="purple_button" @click="changePwdClick" data-testid="changePw">{{
          $t('common.button.changePw')
        }}</el-button>
        <el-button class="purple_button" @click="$emit('forgotPwdClick')" data-testid="forgetPw">
          {{ $t('common.button.forgetPw') }}
        </el-button>
      </div>
    </vDialog>
  </div>
</template>

<script>
import vDialog from './vDialog';
import { apiReq_reset_mt4_password } from '@/resource';

export default {
  props: {
    passwordVisible: Boolean,
    forgotPwdVisible: Boolean,
    number: Number,
    uuid: [Number, String]
  },
  components: { vDialog },
  data() {
    return {
      visible: false
    };
  },
  watch: {
    passwordVisible(bool) {
      this.visible = bool;
    },
    visible(bool) {
      this.$emit('update:passwordVisible', bool);
    }
  },
  methods: {
    changePwdClick() {
      this.$emit('update:passwordVisible', false);
      this.$parent.$parent.changePasswordVisible = true;
    },
    sendResetPasswordEmail() {
      const baseUrl = location.protocol + '//' + location.hostname;
      return apiReq_reset_mt4_password({
        userId: this.uuid,
        mtAccount: this.number,
        baseUrl: baseUrl
      }).then(resp => {
        if (resp.data.code == 0) {
          if (resp.data.data) {
            console.log('The reset password email is sent out');
            return Promise.resolve(true);
          } else {
            console.log('failed to sent out the reset password');
          }
        } else {
          console.log('Errors happened when applying for the reset password emails');
        }
        return Promise.resolve(false);
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/components/home/password.scss';
</style>
