<template>
  <div class="home_common_dialog">
    <vDialog :visible.sync="visible" :visibleFooter="true">
      <slot></slot>
    </vDialog>
  </div>
</template>

<script>
import vDialog from './vDialog';

export default {
  props: {
    homeVisible: Boolean
  },
  components: { vDialog },
  data() {
    return {
      visible: false
    };
  },
  watch: {
    homeVisible(bool) {
      this.visible = bool;
    },
    visible(bool) {
      this.$emit('update:homeVisible', bool);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/components/home/common.scss';
</style>
