<template>
  <div id="home">
    <div class="content_box">
      <div class="inner">
        <div class="link_parent_box">
          <slot name="alerts"></slot>
          <div class="link_box" v-if="showLink || idPass">
            <ul>
              <li>
                <div class="left">
                  <p class="location">{{ $t('common.button.depositFunds') }}</p>
                </div>
                <div class="right">
                  <a @click="getDepositBlacklist()" data-testid="depositFunds">
                    <div class="redirect">
                      <i class="el-icon-arrow-right"></i>
                    </div>
                  </a>
                </div>
              </li>
              <li>
                <div class="left">
                  <p class="location">{{ $t('common.button.withdrawFunds') }}</p>
                </div>
                <div class="right">
                  <a @click="getWithdrawalBlacklist()" data-testid="withdrawFunds">
                    <div class="redirect">
                      <i class="el-icon-arrow-right"></i>
                    </div>
                  </a>
                </div>
              </li>
              <li v-if="openAdditionAccountEnabled">
                <div class="left">
                  <p class="location">{{ $t('menu.openAdditAcc') }}</p>
                </div>
                <div class="right">
                  <a @click="$router.push('openLiveAccount')" data-testid="openLiveAccount">
                    <div class="redirect">
                      <i class="el-icon-arrow-right"></i>
                    </div>
                  </a>
                </div>
              </li>
              <li>
                <div class="left">
                  <p class="location">{{ $t('common.button.download') }}</p>
                </div>
                <div class="right">
                  <a @click.stop.prevent="$router.push('downloads')" data-testid="downloads">
                    <div class="redirect">
                      <i class="el-icon-arrow-right"></i>
                    </div>
                  </a>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <slot></slot>
      </div>
    </div>
    <slot name="components"></slot>
  </div>
</template>

<script>
import { apiID3Status } from '@/resource/register';
import openAccountMixin from '@/mixins/openAccount';
import blackList from '@/mixins/blackList';

export default {
  name: 'HomeTemplate',
  mixins: [openAccountMixin, blackList],
  props: {
    showLink: { type: Boolean, default: true }
  },
  mounted() {
    if (!this.idPass) this.checkIdPass();
  },
  methods: {
    checkIfIBUser() {
      const userType = this.$store.state.common.userType;
      //ib type: individual 5, company 16
      if (userType === 5 || userType === 16) return true;
      return false;
    },
    checkIdPass() {
      apiID3Status().then(resp => {
        if (resp.data.data) this.$store.commit('common/setIdPass', true);
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/pages/home.scss';
</style>
